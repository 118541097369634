import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import "firebase/database";
import 'firebase/compat/storage';
import { ref as sRef } from 'firebase/storage';
import { getStorage } from "firebase/storage";


import React, { useContext, useState } from "react";
import { addDoc, deleteDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
export const firebaseConfig = firebase.initializeApp({
    apiKey: "AIzaSyBQgtJz-0-rVxaKKdvZifVHsVy0vtdSpmc",
    authDomain: "momentum-f789e.firebaseapp.com",
    projectId: "momentum-f789e",
    storageBucket: "momentum-f789e.appspot.com",
    messagingSenderId: "712226124031",
    appId: "1:712226124031:web:be1f983ba892a2bb56dcca",
    measurementId: "G-RW2TN497QW"
});
export const auth = firebase.auth();
export const googleProvider = new firebase.auth.GoogleAuthProvider();
export const appleProvider = new firebase.auth.OAuthProvider('apple.com');
export const firestore = firebase.firestore();
export const db = firebase.firestore();
export const onSnapshot = firebase.firestore();
export const collection = firebase.firestore();

// Firebase storage reference
export const refStorage = getStorage(firebaseConfig);
export const serverStamp = firebase.firestore.Timestamp

const projectStorage = firebase.storage();
const projectFirestore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { projectStorage, projectFirestore, timestamp };


// export const storage = firebase.storage();


export const createUserDocument = async (user) => {
    if (!user) return;
    const userRef = firestore.doc(`user/${user.uid}`);
    const snapshot = await userRef.get();
    if (!snapshot.exists) {
        try {
            await userRef.set({
                ...user,
                createdAt: new Date(),
            });
            console.log("User created");
        } catch (error) {
            console.log("Error in creating user", error);
        }
    }
};

export const deleteTask = (id) => {
    const task = doc(db, "Task", id);
    return deleteDoc(task);
};

export const updateTask = (id, updatedTask) => {
    const task = doc(db, "Task", id);
    return updateDoc(task, updatedTask);
};

export const getTask = (id) => {
    const task = doc(db, "Task", id);
    return getDoc(task);
};

export const getUserProfile = (id, updatedUserProfile) => {
    const userprofile = doc(db, "user", id);
    return updateDoc(userprofile, updatedUserProfile);
};

export function logout() {
    auth.signOut();
}
