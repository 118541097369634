import * as Yup from 'yup';
import { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider, Formik } from 'formik';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel, Button, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from 'src/helpers/firebase';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    // validationSchema: LoginSchema,
    onSubmit: async (e) => {
      e.preventDefault();
      function onLogin() {
        signInWithEmailAndPassword(auth, email, password).catch((error) =>
          console.log(error)
        );

      }
      onLogin();
      // navigate("/home");
    },
  });

  const { errors, touched, values, isSubmitting, getFieldProps } = formik;


  const handleSubmit = (e) => {
    e.preventDefault();
    function onLogin() {
      signInWithEmailAndPassword(auth, email, password).then(
        user => {
          console.log(user)
          navigate("/home")
        }).catch((error) =>
          alert(error)
        );

      // console.log(navigate)

    }
    onLogin();
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <TextField
              fullWidth
              placeholder='Email'
              // autoComplete="username"
              // type="email"
              // label="Email address"
              onChange={(e) => setEmail(e.target.value)}
            // {...getFieldProps('email')}
            // error={Boolean(touched.email && errors.email)}
            // helperText={touched.email && errors.email}
            />

            <TextField
              fullWidth
              placeholder='Password'
              // autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              // label="Password"
              // {...getFieldProps('password')}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPassword} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
              label="Remember me"
            />

            <Link component={RouterLink} variant="subtitle2" to="#" underline="hover">
              Forgot password?
            </Link>
          </Stack>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
            Login
          </LoadingButton>
        </Form>
      </FormikProvider>

      {/* <div>
        <form className="loginForm" onSubmit={handleSubmit}>
          <input
            placeholder="email"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          ></input>
          <input
            placeholder="password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          ></input>
          <button>Login</button>
        </form>
      </div> */}
    </>
  );
}