import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, deleteTask, getTask, logout, updateTask } from "src/helpers/firebase";

// @mui
import { Table, Form, InputGroup, ButtonGroup } from "react-bootstrap";
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Snackbar, Stack, Modal, Box, Card, CardContent, CardHeader, InputLabel, Select, MenuItem, TextField, Alert, FormControl, } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Colxx, Separator } from 'src/components/common/CustomBootstrap';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu, FormGroup, Row, CardBody, List, Collapse, Input, Spinner,
} from 'reactstrap';
import Label from 'src/components/Label';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import date from 'date-and-time';

// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  Timer,
  TimeModal,
} from '../sections/@dashboard/app';
import { LoadingButton } from '@mui/lab';
import { useAuthState } from 'react-firebase-hooks/auth';
import TaskDataService from "./task.service";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '25px'
};

export const ProjectTask = ({ match }) => {
  const [user1, loading] = useAuthState(auth);
  const [taskId, setTaskId] = useState("");
  const [hour, setHour] = useState(parseInt(0));
  const [minute, setMinute] = useState(parseInt(0));
  const [second, setSecond] = useState(parseInt(0));
  const [project, setProject] = useState("");
  const [task, setTask] = useState("")
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [flag, setFlag] = useState(true);
  const [invoicedvariant, setInvoicedVariant] = useState("outlined")
  const [notinvoicedvariant, setNotInvoicedVariant] = useState("outlined")
  const [invoiced, setInvoiced] = useState(false);
  const [message, setMessage] = useState({ error: false, msg: "" });
  const now = new Date();
  const todaysdatetime = date.format(now, 'DD MMM YYYY HH:mm:ss A ');
  const [newDocId, setNewDocId] = useState('');
  const docid = db.collection('Task').doc().id;
  const [user] = useAuthState(auth);
  const [projectList, setProjectList] = useState([])
  const [filter,setFilter] = useState(false)

  const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


  const getTaskIdHandler = (id) => {
    handleOpen();
    // console.log("The ID of document to be edited: ", id);
    setTaskId(id);
  };

  const [tasks, setTasks] = useState([]);
  useEffect(() => {
    getTasks();
  }, []);

  const getTasks = async () => {
    const data = await TaskDataService.getAllTasks();
    // console.log(data.docs);
    setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await TaskDataService.deleteTask(id);
    getTasks();
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage("");
    const hourNumber = parseInt(hour)
    const minuteNumber = parseInt(minute)
    const secondNumber = parseInt(second)
    const newTask = {
      hour: hourNumber,
      minute: minuteNumber,
      second: secondNumber,
      project,
      task,
      invoiced,
      date: todaysdatetime,
      dataid: docid,
      uid: user.uid,
    };
    // console.log(newTask);

    try {
      if (taskId !== undefined && taskId !== "") {
        await TaskDataService.updateTask(taskId, newTask);
        setTaskId("");
        setMessage({ error: false, msg: "Updated successfully!" });
      } else {
        await TaskDataService.addTasks(newTask);
        setMessage({ error: false, msg: "New Task added successfully!" });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setHour("");
    setMinute("");
    setSecond("");
    setProject("");
    setTask("");
    setInvoiced(false)
    setNotInvoicedVariant("outlined")
    setInvoicedVariant("outlined")
    handleClose();
    getTasks();
    await sleep(3000);
    setMessage("");
  };

  const editHandler = async () => {
    setMessage("");
    try {
      const docSnap = await TaskDataService.getTask(taskId);
      // console.log("the record is :", docSnap.data());
      setTask(docSnap.data().task);
      setProject(docSnap.data().project);
      setHour(docSnap.data().hour);
      setMinute(docSnap.data().minute);
      setSecond(docSnap.data().second);
      setInvoiced(docSnap.data().invoiced)
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
    getTasks();
  };

  const [allInvoiced, setAllInvoiced] = useState()

  const setAllInvoicedTrue = async () => {
    try {
      console.log(tasks.invoiced)
      setAllInvoiced(tasks.map(({ doc }) => doc))
      console.log(allInvoiced);

      if(allInvoiced === false) {
        console.log("HELLO");
      }

      // setTask(docSnap.data().task);
      // setProject(docSnap.data().project);
      // setHour(docSnap.data().hour);
      // setMinute(docSnap.data().minute);
      // setSecond(docSnap.data().second);
      // setInvoiced(true);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
    getTasks();
  };

  useEffect(() => {
    console.log("The id here is : ", taskId);
    if (taskId !== undefined && taskId !== "") {
      editHandler();
    }
  }, [taskId]);


  // User Total Projects
  const fetchTotalProjects = async () => {
    try {
      const query = await db
        .collection("Projects")
        .where("uid", "==", user?.uid)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {

            const data = doc.data();
            tempData.push(data);
          });
          setProjectList(tempData);
        });
      // const data = await query.docs[0].data();
      // setProjectList(data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTotalProjects();
  }, [user, projectList])

  const tasksSorted =
    tasks.sort((date1, date2) => {
      if (date1.date > date2.date) return 1;
      if (date1.date < date2.date) return -1;
    }).reverse();


    const config = {
      headers: {
        accept: 'application/json',
      },
      data: {},
    };

    // const getTogglTasks = () => {
    //   const config = {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'accept':'application/json',
    //       'Authorization': `Basic ${base64.encode("hello@tomglland.com.au","T0mg4ll4nD!")}`
    //     },
    //   };
    //   return function (dispatch) {
    //     return axios.get('https://api.track.toggl.com/api/v9/me/time_entries',config)
    //       .then(response => {
    //         if (response.status === 200) {
    //           console.log("actions ",response.data);
    //         }
    //       }).catch(err => {
    
    //       });
    //   }

    // }

    // curl  https://api.track.toggl.com/api/v9/me/time_entries \
    // -H "Content-Type: application/json" \
    // -u hello@tomgalland.com.au:T0mg4ll4nD!
  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

            <Typography variant="h4" sx={{ mb: 5 }}>
              Tasks
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={0}>
            <Button variant="outline" onClick={()=>setFilter(true)} startIcon={<Iconify icon="akar-icons:filter" />}>
                Not Invoiced {filter}
              </Button>
              &nbsp;
              &nbsp;
            <Button variant="outline" onClick={()=>setAllInvoicedTrue()} startIcon={<Iconify icon="akar-icons:filter" />}>
                All Invoiced
              </Button>
              &nbsp;
              &nbsp;
              <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                New Time
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: 800 }}>
                  <Form onSubmit={handleSubmit}>
                    <Stack spacing={1}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={6}>
                        <TextField
                          type="number"
                          placeholder="Hour"
                          value={hour}
                          onChange={(e) => setHour(e.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          type="number"
                          placeholder="Minute"
                          value={minute}
                          onChange={(e) => setMinute(e.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          type="number"
                          placeholder="Seconds"
                          value={second}
                          onChange={(e) => setSecond(e.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                      </Stack>

                      <FormControl fullWidth>
                        <InputLabel >Project</InputLabel>
                        <Select
                          id="demo-simple-select"
                          label="Age"
                          onChange={(event) => {
                            event.preventDefault()
                            setProject(event.target.value)
                          }}
                        >
                          {projectList.map((user, e, index) => {
                            return (
                              <MenuItem
                                className="church-select-item"
                                key={user.name}
                                id="category"
                                value={user.name}
                                defaultValue=""
                              >
                                {user.name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>

                      <TextField
                        type="text"
                        placeholder="Task"
                        value={task}
                        onChange={(e) => setTask(e.target.value)}
                      />
                      <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3}>
                        <Button
                          variant={invoicedvariant}
                          onClick={(e) => {
                            setInvoiced(true);
                            setNotInvoicedVariant("outlined")
                            setInvoicedVariant("contained")
                            setFlag(true);
                          }}
                        >
                          Invoiced
                        </Button>
                        <Button
                          variant={notinvoicedvariant}
                          onClick={(e) => {
                            setInvoiced(false);
                            setInvoicedVariant("outlined")
                            setNotInvoicedVariant("contained")
                            setFlag(false);
                          }}
                        >
                          Not Invoiced
                        </Button>
                      </Stack>

                      <div className="d-grid gap-2" style={{ textAlign: 'center' }}>
                        <Button variant="contained" type="Submit" style={{ width: 200, alignItems: "center" }}>
                          Save
                        </Button>
                      </div>
                    </Stack>
                  </Form>
                </Box>
              </Modal>
            </Stack>
          </Stack>
          <Stack alignItems="center" justifyContent="center" mb={5}>
            {message?.msg && (
              <Alert
                variant={message?.error ? "danger" : "success"}
                dismissible
                onClose={() => setMessage("")}
              >
                {message?.msg}
              </Alert>
            )}
          </Stack>
          <Grid container spacing={0} >
            {tasksSorted.map((doc, index) => {
              if (doc.uid === user1.uid)
              if(filter === true && doc.invoiced === false) 
                return (
                  <Stack justifyContent="space-evenly" style={{ padding: "10px" }} >
                    <Stack justifyContent="space-evenly">
                      <Card style={{ margin: "0px", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "10px", width: "200px", textAlign: "left" }} key={doc.docId} justifyContent="space-evenly" >

                        <Stack direction="column" justifyContent="space-evely"  >
                          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.task}</Typography>
                          <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.hour} hours</Typography>

                          <Typography variant="subtitle2" style={{ fontWeight: "light" }} > {doc.project}</Typography>
                          {doc.invoiced === true ? (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              Invoiced
                            </Typography>
                          ) : (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              Not Invoiced
                            </Typography>
                          )}

                          {doc.date != null ? (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              {doc.date}
                            </Typography>
                          ) : (
                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              NO DATE
                            </Typography>
                          )}

                          <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: "10px" }}>
                            <Button variant="contained" onClick={(e) => getTaskIdHandler(doc.id)}>
                              Edit
                            </Button>
                            <Button variant='contained' style={{ backgroundColor: "red" }} onClick={(e) => deleteHandler(doc.id)}>
                              Delete
                            </Button>
                          </Stack>
                        </Stack>
                      </Card>
                    </Stack>
                  </Stack>
                );
                if(filter === false){
                  return (
                    <Stack justifyContent="space-evenly" style={{ padding: "10px" }}>
                      <Stack justifyContent="space-evenly">
                        <Card style={{ margin: "0px", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "10px", width: "200px", textAlign: "left" }} key={doc.docId} justifyContent="space-evenly" >
  
                          <Stack direction="column" justifyContent="space-evely"  >
                            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.task}</Typography>
                            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.hour} hours</Typography>
  
                            <Typography variant="subtitle2" style={{ fontWeight: "light" }} > {doc.project}</Typography>
                            {doc.invoiced === true ? (
                              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                Invoiced
                              </Typography>
                            ) : (
                              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                Not Invoiced
                              </Typography>
                            )}
  
                            {doc.date != null ? (
                              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                {doc.date}
                              </Typography>
                            ) : (
                              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                                NO DATE
                              </Typography>
                            )}
  
                            <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: "10px" }}>
                              <Button variant="contained" onClick={(e) => getTaskIdHandler(doc.id)}>
                                Edit
                              </Button>
                              <Button variant='contained' style={{ backgroundColor: "red" }} onClick={(e) => deleteHandler(doc.id)}>
                                Delete
                              </Button>
                            </Stack>
                          </Stack>
                        </Card>
                      </Stack>
                    </Stack>
                  );
                }
            })}
          </Grid>

        </Container>
      </Page >
    </>
  );
};
export default ProjectTask;
