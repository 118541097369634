import React, { useEffect, useState } from "react";
import './Timer.css'
import { Stack, Button, Divider, Typography } from '@mui/material';

const Timer = () => {
    const [time, setTime] = useState(0);
    const [timerOn, setTimerOn] = useState(false);

    useEffect(() => {
        let interval = null;

        if (timerOn) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime + 10);
            }, 10);
        } else if (!timerOn) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [timerOn]);

    return (
        <div className="Timers">
            <h2>Stopwatch</h2>
            <div id="display">
                <span>{("0" + Math.floor((time / 60000) % 60)).slice(-2)}:</span>
                <span>{("0" + Math.floor((time / 1000) % 60)).slice(-2)}</span>
                {/* <span>{("0" + ((time / 10) % 100)).slice(-2)}</span> */}
            </div>

            <div id="buttons">
                {!timerOn && time === 0 && (
                    <Button variant="contained" onClick={() => setTimerOn(true)}>Start</Button>
                )}
                {timerOn && <Button onClick={() => setTimerOn(false)}>Stop</Button>}
                {!timerOn && time > 0 && (
                    <Button variant="contained" onClick={() => setTime(0)}>Reset</Button>
                )}
                {!timerOn && time > 0 && (
                    <Button variant="contained" onClick={() => setTimerOn(true)}>Resume</Button>
                )}
            </div>
        </div>
    );
};

export default Timer;