import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Link, Button, Drawer, Typography, Avatar, Stack } from '@mui/material';
// mock
import account from '../../_mock/account';
// hooks
import useResponsive from '../../hooks/useResponsive';
// components
import Logo from '../../components/Logo';
import Scrollbar from '../../components/Scrollbar';
import NavSection from '../../components/NavSection';
//
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, db } from 'src/helpers/firebase';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  // Current User
  const [user1, loading] = useAuthState(auth);
  const [myDisplayName, setMyDisplayName] = useState("");
  const [trackingType, setTrackingType] = useState("")

  const fetchUserName = async () => {
    try {
      const query = await db
        .collection("user")
        .where("uid", "==", user1?.uid)
        .get();
      const data = await query.docs[0].data();
      setMyDisplayName(data);
      setTrackingType(data.trackingType);
      console.log(data.trackingType)
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (!myDisplayName) fetchUserName();
    // if(trackingType == '')
  }, [user1, myDisplayName, loading]);

  const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

  const navConfig = trackingType === "Toggl" ? [
    {
      title: 'dashboard',
      path: '/home',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    // {
    //   title: 'Tasks',
    //   path: '/tasks',
    //   icon: getIcon('carbon:task-complete'),
    // },
    // {
    //   title: 'Board',
    //   path: '/board',
    //   icon: getIcon('fluent:board-16-filled'),
    // },
    {
      title: 'Invoices',
      path: '/invoices',
      icon: getIcon('fa-solid:file-invoice'),
    },
    // {
    //   title: 'Quotes',
    //   path: '/quotes',
    //   icon: getIcon('la:file-invoice'),
    // },
    {
      title: 'Clients',
      path: '/clients',
      icon: getIcon('bi:file-person-fill'),
    },
    {
      title: 'user',
      path: '/user',
      icon: getIcon('eva:people-fill'),
    },
    // {
    //   title: 'login',
    //   path: '/login',
    //   icon: getIcon('eva:lock-fill'),
    // },

    // {
    //   title: 'register',
    //   path: '/register',
    //   icon: getIcon('eva:person-add-fill'),
    // },
    // {
    //   title: 'Not found',
    //   path: '/404',
    //   icon: getIcon('eva:alert-triangle-fill'),
    // },
  ] : [
    {
      title: 'dashboard',
      path: '/home',
      icon: getIcon('eva:pie-chart-2-fill'),
    },
    {
      title: 'Tasks',
      path: '/tasks',
      icon: getIcon('carbon:task-complete'),
    },
    // {
    //   title: 'Board',
    //   path: '/board',
    //   icon: getIcon('fluent:board-16-filled'),
    // },
    {
      title: 'Invoices',
      path: '/invoices',
      icon: getIcon('fa-solid:file-invoice'),
    },
    // {
    //   title: 'Quotes',
    //   path: '/quotes',
    //   icon: getIcon('la:file-invoice'),
    // },
    {
      title: 'Clients',
      path: '/clients',
      icon: getIcon('bi:file-person-fill'),
    },
    {
      title: 'user',
      path: '/user',
      icon: getIcon('eva:people-fill'),
    },
  ];

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
        <Logo />
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none" component={RouterLink} to="#">
          <AccountStyle>
            <Avatar src={account.photoURL} alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
                {myDisplayName.firstName} {myDisplayName.lastName}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
          <Box
            component="img"
            src="/static/illustrations/illustration_avatar.png"
            sx={{ width: 100, position: 'absolute', top: -50 }}
          />

          <Box sx={{ textAlign: 'center' }}>
            <Typography gutterBottom variant="h6">
              Get more?
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              From only $69
            </Typography>
          </Box>

          <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button>
        </Stack>
      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
