import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import ProjectTask from './task/ProjectTask';
import User from './user/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Register from './pages/Register';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import InvoicePage from './invoice/InvoicePage';
import ClientPage from './clients/ClientPage'
import QuotePage from './quote/QuotePage';
import TaskBoard from './taskboard/TaskBoard';
// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/home" /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <Register /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '/',
      element: <DashboardLayout />,
      children: [
        { path: 'home', element: <DashboardApp /> },
        { path: 'user', element: <User /> },
        { path: 'products', element: <Products /> },
        { path: 'tasks', element: <ProjectTask /> },
        { path: 'board', element: <TaskBoard /> },
        { path: 'invoices', element: <InvoicePage /> },
        { path: 'quotes', element: <QuotePage /> },
        { path: 'clients', element: <ClientPage /> },
      ],
    },

    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
