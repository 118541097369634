import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR
  // const logo = <Box component="img" src="/static/logo.svg" sx={{ width: 40, height: 40, ...sx }} />

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="100%" viewBox="0 0 500 500" enable-background="new 0 0 500 500" >
        <path fill="#FFFFFF" opacity="1.000000" stroke="none"
          d="
M278.000000,501.000000 
	C185.333359,501.000000 93.166710,501.000000 1.000049,501.000000 
	C1.000033,334.333405 1.000033,167.666794 1.000016,1.000139 
	C167.666565,1.000093 334.333130,1.000093 500.999756,1.000046 
	C500.999847,167.666519 500.999847,334.333038 500.999939,500.999786 
	C426.833344,501.000000 352.666656,501.000000 278.000000,501.000000 
M368.281799,252.360641 
	C394.943207,248.144272 416.243317,235.107254 431.675262,212.994995 
	C458.637909,174.360596 450.802246,120.615768 413.993195,90.291191 
	C412.411743,88.988335 410.419373,88.184288 408.104950,86.739067 
	C388.510925,58.927044 362.050781,44.418751 327.682068,44.810913 
	C306.854340,45.048569 286.017792,44.609058 265.192841,44.946236 
	C252.570160,45.150608 239.880524,45.578606 227.362625,47.084049 
	C188.130402,51.802235 153.825928,68.166977 125.102150,94.857193 
	C76.026657,140.458282 55.374889,197.099884 63.961540,263.713196 
	C70.810669,316.847107 98.256920,358.523346 138.093842,393.612671 
	C148.225906,402.714600 157.710815,412.692413 168.615082,420.749237 
	C197.794846,442.309326 230.803619,454.540771 267.134003,455.808167 
	C296.076080,456.817810 325.078369,456.190247 354.053680,456.135559 
	C387.214111,456.072968 412.951782,441.571564 431.763641,414.785950 
	C454.940308,381.785431 452.842926,337.079742 426.533722,304.381104 
	C421.270844,297.840088 414.097229,292.836395 407.223419,286.781219 
	C397.361908,271.493866 383.817841,260.496765 368.281799,252.360641 
z"/>
        <path fill="#A134F6" opacity="1.000000" stroke="none"
          d="
M138.076782,392.927246 
	C98.256920,358.523346 70.810669,316.847107 63.961540,263.713196 
	C55.374889,197.099884 76.026657,140.458282 125.102150,94.857193 
	C153.825928,68.166977 188.130402,51.802235 227.362625,47.084049 
	C239.880524,45.578606 252.570160,45.150608 265.192841,44.946236 
	C286.017792,44.609058 306.854340,45.048569 327.682068,44.810913 
	C362.050781,44.418751 388.510925,58.927044 408.475342,87.280701 
	C433.451965,128.090393 427.001801,175.465500 392.112549,205.937561 
	C367.035248,227.839874 337.806488,233.424515 305.731903,225.616653 
	C293.477203,222.633499 281.289062,219.282364 268.913849,216.920151 
	C259.973358,215.213562 253.011749,219.670258 248.270950,227.093842 
	C243.957062,233.848877 243.815659,241.113251 248.324738,247.814087 
	C253.431946,255.403778 260.966003,258.181244 270.408356,257.112671 
	C285.777649,253.473373 300.717285,249.801056 316.119873,246.110016 
	C334.175140,243.579300 351.196533,245.465500 367.523041,252.704361 
	C383.817841,260.496765 397.361908,271.493866 407.607544,287.289551 
	C421.699921,309.772125 427.142456,333.503479 421.474335,358.954224 
	C411.984161,401.566559 373.889923,431.778259 330.142090,431.976501 
	C305.145752,432.089752 280.148010,431.900055 255.151733,432.018982 
	C213.469604,432.217285 175.960144,419.790985 142.391327,395.193634 
	C141.095398,394.244049 139.521271,393.674103 138.076782,392.927246 
z"/>
        <path fill="#FAFE02" opacity="1.000000" stroke="none"
          d="
M138.085312,393.269958 
	C139.521271,393.674103 141.095398,394.244049 142.391327,395.193634 
	C175.960144,419.790985 213.469604,432.217285 255.151733,432.018982 
	C280.148010,431.900055 305.145752,432.089752 330.142090,431.976501 
	C373.889923,431.778259 411.984161,401.566559 421.474335,358.954224 
	C427.142456,333.503479 421.699921,309.772125 407.898132,287.460846 
	C414.097229,292.836395 421.270844,297.840088 426.533722,304.381104 
	C452.842926,337.079742 454.940308,381.785431 431.763641,414.785950 
	C412.951782,441.571564 387.214111,456.072968 354.053680,456.135559 
	C325.078369,456.190247 296.076080,456.817810 267.134003,455.808167 
	C230.803619,454.540771 197.794846,442.309326 168.615082,420.749237 
	C157.710815,412.692413 148.225906,402.714600 138.085312,393.269958 
z"/>
        <path fill="#FAFE02" opacity="1.000000" stroke="none"
          d="
M269.978729,257.079651 
	C260.966003,258.181244 253.431946,255.403778 248.324738,247.814087 
	C243.815659,241.113251 243.957062,233.848877 248.270950,227.093842 
	C253.011749,219.670258 259.973358,215.213562 268.913849,216.920151 
	C281.289062,219.282364 293.477203,222.633499 305.731903,225.616653 
	C337.806488,233.424515 367.035248,227.839874 392.112549,205.937561 
	C427.001801,175.465500 433.451965,128.090393 408.732086,87.485107 
	C410.419373,88.184288 412.411743,88.988335 413.993195,90.291191 
	C450.802246,120.615768 458.637909,174.360596 431.675262,212.994995 
	C416.243317,235.107254 394.943207,248.144272 367.902405,252.532501 
	C351.196533,245.465500 334.175140,243.579300 315.793335,245.973526 
	C309.060974,244.321442 303.175903,242.491974 297.163239,241.313339 
	C284.051910,238.743195 273.794922,244.832138 269.978729,257.079651 
z"/>
        <path fill="#FEFDFB" opacity="1.000000" stroke="none"
          d="
M270.408356,257.112671 
	C273.794922,244.832138 284.051910,238.743195 297.163239,241.313339 
	C303.175903,242.491974 309.060974,244.321442 315.330444,245.992249 
	C300.717285,249.801056 285.777649,253.473373 270.408356,257.112671 
z"/>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
