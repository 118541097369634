import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import { Stack, TextField, IconButton, InputAdornment, } from '@mui/material';
import { LoadingButton } from '@mui/lab';

// component
import Iconify from '../../../components/Iconify';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from 'src/helpers/firebase';
import { ref, set } from "firebase/database";
import { addDoc, collection, updateDoc } from 'firebase/firestore';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
    lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
    },
    onSubmit: async (e) => {
      e.preventDefault();
      function onRegister() {
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            set(ref(db, "user/" + userCredential.user.uid), {
              firstName: firstName,
              lastName: lastName,
              email: email,
            });
          })
          .catch((error) => console.log(error));

      }
      onRegister();
    },
  });

  const postsCollectionRef = collection(db, 'user');

  const handleSubmit = (e) => {
    e.preventDefault();
    function onRegister() {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log(userCredential.user.uid)
          const doDocAction = (docRef, action) => (
            action(docRef, {
              firstName: firstName,
              lastName: lastName,
              email: email,
              uid: userCredential.user.uid
            })
          );
          doDocAction(postsCollectionRef, addDoc);
          if (userCredential.user.uid === null) {
            console.log(userCredential.user.uid)
            console.log("error")
          } else if (userCredential.user.uid !== null) {
            console.log(userCredential.user.uid)
            navigate("/");
          };
        })
        .catch((error) => alert(error));
    }
    onRegister();

  };

  const { errors, touched, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              placeholder='First Name'
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
            />

            <TextField
              placeholder='Last Name'
              fullWidth
              onChange={(e) => setLastName(e.target.value)}
            />
          </Stack>

          <TextField
            placeholder='Email'
            fullWidth
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            fullWidth
            placeholder='Password'
            onChange={(e) => setPassword(e.target.value)}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" >
            Register
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}