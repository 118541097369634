import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from 'firebase/firestore';
import { auth, db, refStorage, serverStamp } from 'src/helpers/firebase';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import {

  Grid,
  Container,
  Typography,
  Button,
  Stack,
  TextField,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import date from 'date-and-time';
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { setDefaultLocale } from 'react-datepicker';
import moment from 'moment';
import InvoiceDataService from './invoice.service';
import AllInvoices from './AllInvoices';

// ----------------------------------------------------------------------

export default function InvoiceDetails() {
  // Current User
  const [user1, loading] = useAuthState(auth);
  const navigate = useNavigate();
  const [blankStatement, setBlankStatement] = useState('');

  const now = new Date();
  const todaysdatetime = date.format(now, 'DD MMM YYYY');

  const later = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  const duedatetime = date.format(later, 'DD MMM YYYY');

  const formik = useFormik({
    initialValues: {
      hour: '',
      minute: '',
      seconds: '',
      project: '',
      task: '',
    },
    onSubmit: async () => { },
  });

  const { } = formik;

  // Get Users Business Details
  const [userBusinessDetails, setUserBusinessDetails] = useState([]);
  const usersCollectionRef = collection(db, 'user');

  // Get Tasks
  const [tasks, setTasks] = useState([]);
  const usersCollectionRefTask = collection(db, 'Task');

  // Current User
  const [clients, setClients] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bsb, setBsb] = useState('');
  const [bankName, setBankName] = useState('');
  const [hourlyRate, setHourlyRate] = useState('');
  const [abn, setAbn] = useState('');
  const [docid, setDocId] = useState('');

  const fetchClients = async () => {
    try {
      const query = await db.collection('Projects').where('uid', '==', user1?.uid).where('name', '==', project).get();
      const data = query.docs[0].data();
      setDocId(data.docid);
      setClients(data.name);
      setAddress(data.streetAddress);
      setCity(data.city);
      setCountry(data.country);
      setPostcode(data.postcode);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchInvoiceNumber = async () => {
    try {
      const query = await db.collection('user').where('uid', '==', user1?.uid).get();
      const data = await query.docs[0].data();
      setInvoiceNumber(data.invoiceNumber);
      // setTotalWeekHours(data.totalweekhours);
    } catch (err) {
      console.error(err);
    }
  };

  // User Total Projects
  const [projectList, setProjectList] = useState([]);
  const [project, setProject] = useState('');
  const fetchTotalProjects = async () => {
    try {
      const query = await db
        .collection('Projects')
        .where('uid', '==', user1?.uid)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tempData.push(data);
          });
          setProjectList(tempData);
          setProjectName(tempData.name);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchTotalProjects();
  });

  const [invoiceTask, setInvoiceTask] = useState([]);
  const [totalHours, setTotalHours] = useState(0);

  // User Tasks Not Invoiced
  const fetchInvoiceTask = async () => {
    try {
      const query = await db
        .collection('Task')
        .where('uid', '==', user1?.uid)
        .where('project', '==', project)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tempData.push(data);
          });
          setInvoiceTask(tempData);
        });
    } catch (err) {
      console.error(err);
    }
  };

  // Fetch Hourly Rate
  const [userHourlyRate, setUserHourlyRate] = useState([]);
  const [latestInvoiceNumber, setLatestInvoiceNumber] = useState([]);
  const [togglAPI, setTogglAPI] = useState("");
  const fetchUserHourlyRate = async () => {
    try {
      const query = await db
        .collection('user')
        .where('uid', '==', user1.uid)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tempData.push(data);
          });
          setUserHourlyRate(tempData);
          setLatestInvoiceNumber(tempData.invoiceNumber);
          setTogglAPI(tempData[0].togglAPI);
        });
      query();
    } catch (err) {
      console.error(err);
    }
  };

  const container = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const exportPDFWithComponent = () => {
    console.log(pdfExportComponent.current, "CURRENT PDF")
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  const [data, setData] = useState(false);

  const [currency, setCurrency] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState([]);
  const [projectName, setProjectName] = useState('');
  const [hours, setHours] = useState();

  const fetchAll = () => {
    setData(true);
    const getUserBusinessDetails = async () => {
      const data = await getDocs(usersCollectionRef);
      setUserBusinessDetails(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getUserBusinessDetails();
    const getTasks = async () => {
      const data = await getDocs(usersCollectionRefTask);
      setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getTasks();
    fetchClients();
    fetchTotalProjects();
    fetchInvoiceTask();
    fetchUserHourlyRate();
    fetchInvoiceNumber();

    function sum(obj) {
      var add = 0;
      for (var i = 0; i < obj.length; i++)
        if (obj[i].invoiced === false) {
          {
            add += obj[i].hour;
          }
          setHours(add);
        }

      return add;
    }
    sum(tasks);

    // Get Currency
    let newCurrency = userBusinessDetails.map((e) => {
      if (e.uid === user1.uid) setCurrency(e.currency);
      return e.currency;
    });

    // // Get Invoice Number
    // let newInvoiceNumber = userBusinessDetails.map((e) => { if (e.uid === user1.uid) return (e.invoiceNumber) });
    // setInvoiceNumber(newInvoiceNumber)

    // Get Hourly Rate
    let newHourlyRateMap = userHourlyRate.map((e) => {
      if (e.uid === user1.uid) return e.hourlyRate;
    });
    setHourlyRate(newHourlyRateMap);
  };

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const fetchAllTasks = async () => {
    fetchAll();
  };

  // curl -u 6f6f0a2e5f220c1b4a9c1a783cc4b627:api_token \ -H "Content-Type: application/json" \ -X GET "https://api.track.toggl.com/api/v9/me/time_entries?start_date=2023-01-16&end_date=2023-01-22"

  useEffect(() => {
    fetchAllTasks();
  }, [invoiceNumber]);

  // console.log(moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'));

  const [totalToggl, setTotalToggl] = useState(0);
  const getTogglHours = () => {
    let encoded = base64_encode(`${togglAPI}:api_token`);
    let startdate = moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD');
    let enddate = moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD');

    console.log(startdate, enddate, "START & END DATE")

    // let encodedUri = encodeURI(uri);
    fetch(`https://api.track.toggl.com/api/v9/me/time_entries?start_date=${startdate}&end_date=${enddate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${encoded}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        // setTotalToggl(json)
        let sum = 0;

        json.forEach((value) => {
          sum += value.duration;
        });
        const roundUp = Math.round(sum / 3600);
        setTotalToggl(roundUp);
        // console.log(json,"JSON")
      })
      .catch((err) => console.error(err, 'ERROR'));
  };

  useEffect(() => {
    if (togglAPI) getTogglHours();
  }, []);

  // Saving Invoice to Firebase Storage
  // State to store uploaded file
  const [file, setFile] = useState("");

  // progress
  const [percent, setPercent] = useState(0);

  // Handle file upload event and update state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const handleUpload = async () => {
    if (!file) {
      alert("Please upload an image first!");
    }

    // const file = pdfExportComponent.current.save();
    // console.log(file, "WHOLE FILE")

    const storageRef = ref(refStorage, `/files/${file.name}`);

    // progress can be paused and resumed. It also exposes progress updates.
    // Receives the storage reference and the file to upload.
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          InvoiceDataService.addInvoices({
            "url": url, "uid": user1.uid, "name": file.name, "dateCreated": serverStamp.now()
          })
        });
      }
    );
  };

  return (
    <>
      <div>
        <div className="example-config">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControl style={{ width: '500px' }}>
              <InputLabel>Project</InputLabel>
              <Select
                id="demo-simple-select"
                label="Age"
                defaultValue=""
                onChange={(event) => {
                  event.preventDefault();
                  setProject(event.target.value);
                }}
              >
                {projectList.map((user, e, index) => {
                  return (
                    <MenuItem
                      className="church-select-item"
                      key={user.name}
                      id="category"
                      value={user.name}
                      defaultValue=" your Project"
                    >
                      {user.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={0}>
              <Button
                variant="contained"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={() => {
                  fetchAllTasks();
                }}
                style={{ marginRight: 20 }}
              >
                Generate Invoice
              </Button>
              {/* <div>
                <input type="file" onChange={handleChange} accept="/image/*" />
                <button onClick={handleUpload}>Upload to Firebase</button>
                <p>{percent} "% done"</p>
              </div> */}
              <Button
                variant="contained"
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={handleUpload}
              >
                Export to PDF
              </Button>
            </Stack>
          </Stack>
        </div>
        {/* <p>{totalToggl}</p> */}
        {/* {data === false ? (
          <></>
        ) : (
          <Stack
            direction={{ xs: 'column', sm: 'column' }}
            style={{ paddingLeft: '10px', paddingRight: '20px' }}
            justifyContent="space-between"
            spacing={2}
          >
            <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Tasks To Be Invoiced
            </Typography>
            <TextField
              placeholder="Total Hours"
              type="text"
              value={totalHours}
              fullWidth
              onChange={(event) => setTotalHours(event.target.value)}
              style={{
                border: 'none',
                backgroundColor: 'white',
                height: '60px',
                fontSize: '30px',
              }}
            />
            {invoiceTask.map((task, e, index) => {
              if (task.invoiced == false)
                return (
                  <>
                    <Typography variant="subtitle1" style={{ fontSize: '15px' }}>
                      - {task.task}
                    </Typography>
                  </>
                );
            })}
            &nbsp; &nbsp;
          </Stack>
        )} */}
        <div style={{}} className="border rounded p-2">
          <PDFExport
            ref={pdfExportComponent}
            paperSize="A4"
            margin={20}
            fileName={`Invoice ${invoiceNumber} - ${todaysdatetime}`}
          >
            <div ref={container}>
              <Card style={{ padding: '20px' }}>
                <FormikProvider value={formik}>
                  <Form autoComplete="off">
                    <Stack spacing={1}>
                      <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                        <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                          {userBusinessDetails.map((business, e, index) => {
                            if (business.uid === user1.uid)
                              return (
                                <>
                                  <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                    {business.businessName}
                                  </Typography>
                                  <Typography variant="body1" style={{ fontSize: '10px' }}>
                                    {business.address}
                                  </Typography>
                                  <Typography variant="body1" style={{ fontSize: '10px' }}>
                                    {business.city}
                                  </Typography>
                                  <Typography variant="body1" style={{ fontSize: '10px' }}>
                                    {business.country}
                                  </Typography>
                                  <Typography variant="body1" style={{ fontSize: '10px' }}>
                                    {business.postcode}
                                  </Typography>
                                  <Typography variant="subtitbody1le1" style={{ fontSize: '10px' }}>
                                    {business.abn}
                                  </Typography>
                                </>
                              );
                          })}
                        </Stack>
                        <div style={{ alignItems: 'end' }}>
                          <h1></h1>
                        </div>
                        <div style={{ alignItems: 'end' }}>
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'bold', fontSize: '20px', color: 'black', textAlign: 'right' }}
                          >
                            {currency} {totalToggl}
                          </Typography>

                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'bold', fontSize: '10px', textAlign: 'right' }}
                          >
                            Invoice {invoiceNumber}
                          </Typography>

                          <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" spacing={0}>
                            <Typography style={{ fontSize: '10px', textAlign: 'right' }}>
                              Issued: {todaysdatetime}
                            </Typography>
                          </Stack>
                          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0}>
                            <Typography style={{ fontSize: '10px', textAlign: 'right' }}>
                              Due Date: {duedatetime}
                            </Typography>
                          </Stack>
                        </div>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="space-between"
                        style={{ paddingBottom: '10px' }}
                        spacing={2}
                      >
                        <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                          <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Bill To:</p>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                            {clients}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                            {address}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                            {city}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                            {country}
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                            {postcode}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        style={{
                          padding: '10px',
                          paddingTop: '10px',
                          borderRadius: '5px',
                          color: 'white',
                          backgroundColor: 'grey',
                        }}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <p style={{ width: '250px', fontSize: '10px' }}>Description</p>
                        <p style={{ fontSize: '10px' }}>Hours</p>
                      </Stack>
                      <Stack
                        direction={{ xs: 'column', sm: 'column' }}
                        style={{ paddingLeft: '10px', paddingRight: '20px' }}
                        justifyContent="space-between"
                        spacing={2}
                      >
                        <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                          <Typography variant="subtitle1" style={{ width: '200px', fontSize: '10px' }}>
                            Weekly Retainer
                          </Typography>
                          <Typography variant="subtitle1" style={{ fontSize: '10px', paddingLeft: '30px' }}>
                            {totalToggl}
                          </Typography>
                        </Stack>
                        {/* {invoiceTask.map((task, e, index) => {
                          if (task.invoiced == false)
                            return (
                              <>
                                <Stack
                                  direction={{ xs: 'column', sm: 'row' }}
                                  justifyContent="space-between"
                                  spacing={2}
                                >
                                  <Typography variant="subtitle1" style={{ width: '200px', fontSize: '10px' }}>
                                    {task.task}
                                  </Typography>
                                  <Typography variant="subtitle1" style={{ fontSize: '10px', paddingLeft: '30px' }}>
                                    {task.hour}
                                  </Typography>
                                </Stack>
                              </>
                            );
                        })} */}
                      </Stack>

                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        justifyContent="end"
                        style={{ paddingTop: '10px' }}
                        spacing={2}
                      >
                        <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}></Stack>
                        <Stack direction={{ xs: 'column', sm: 'column' }} style={{ paddingRight: '20px' }} spacing={2}>
                          <>
                            <Stack
                              direction={{ xs: 'column', sm: 'column' }}
                              justifyContent="space-between"
                              spacing={0}
                            >
                              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={0}>
                                <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
                                  Hourly Rate
                                </Typography>
                                <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
                                  {currency} {hourlyRate}
                                </Typography>
                              </Stack>
                              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
                                  Total
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  style={{ fontWeight: 'bold', fontSize: '10px', color: 'black' }}
                                >
                                  AUD {totalToggl * hourlyRate}
                                </Typography>
                              </Stack>
                              <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                <Typography variant="subtitle1" style={{ fontSize: '10px' }}>
                                  Amount Due
                                </Typography>
                                <Typography
                                  variant="subtitle1"
                                  style={{ fontWeight: 'bold', fontSize: '10px', color: 'black' }}
                                >
                                  AUD {totalToggl * hourlyRate}
                                </Typography>
                              </Stack>
                            </Stack>
                          </>
                        </Stack>
                      </Stack>
                      <p style={{ fontSize: '10px', fontWeight: 'bold' }}>Notes</p>
                      <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                        {userBusinessDetails.map((business, e, index) => {
                          return (
                            <>
                              <Typography variant="body1" style={{ fontSize: '10px' }}>
                                {business.bankName}
                              </Typography>
                              <Typography variant="body1" style={{ fontSize: '10px' }}>
                                {business.bankAccountName}
                              </Typography>
                              <Typography variant="body1" style={{ fontSize: '10px' }}>
                                {business.bankAccount}
                              </Typography>
                              <Typography variant="body1" style={{ fontSize: '10px' }}>
                                {business.bsb}
                              </Typography>
                            </>
                          );
                        })}
                      </Stack>
                    </Stack>
                  </Form>
                </FormikProvider>
              </Card>
            </div>
          </PDFExport>
        </div>
      </div>
      <AllInvoices />
    </>
  );
}
