import React, { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
import { auth, db, logout } from 'src/helpers/firebase';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';
import { query, collection, getDocs, where, getDoc, doc, limit } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { decode as base64_decode, encode as base64_encode } from 'base-64';

// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  Stack,
  Modal,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';

// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  Timer,
  TimeModal,
} from '../sections/@dashboard/app';
import Input from 'src/theme/overrides/Input';
import firebase from 'firebase/compat/app';
import { List } from 'reactstrap';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import NewProjectModal from 'src/sections/@dashboard/app/NewProjectModal';
import InvoiceModal from 'src/sections/@dashboard/app/InvoiceModal';
import Email from 'src/email/email';
import { createCheckoutSession } from 'src/stripe/createCheckoutSession';
import { useContext } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { currentUser } from 'src/constants/defaultValues';
import moment from 'moment';

// ----------------------------------------------------------------------

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '25px',
};

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

export default function DashboardApp() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openNewProject, setOpenNewProject] = useState(false);
  const handleOpenProject = () => setOpenNewProject(true);
  const handleCloseProject = () => setOpenNewProject(false);
  const [openinvoice, setOpenInvoice] = useState(false);
  const handleOpenInvoice = () => setOpenInvoice(true);
  const handleCloseInvoice = () => setOpenInvoice(false);

  const [usersTasks, setUsersTasks] = useState([]);
  const [user, setUser] = useState([]);
  const [project, setProject] = useState([]);
  const usersCollectionRef = collection(db, 'Task');
  const usersCollectionRefUser = collection(db, 'user');
  const usersCollectionRefProject = collection(db, 'Projects');

  // Current User
  const [user1, loading] = useAuthState(auth);
  const [myDisplayName, setMyDisplayName] = useState('');
  const [hourlyRate, setHourlyRate] = useState();

  const fetchUserName = async () => {
    try {
      const query = await db.collection('user').where('uid', '==', user1?.uid).get();
      const data = await query.docs[0].data();
      setMyDisplayName(data.firstName);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchUserHourlyRate = async () => {
    try {
      const query = await db.collection('user').where('uid', '==', user1?.uid).get();
      const data = await query.docs[0].data();
      setHourlyRate(data.hourlyRate);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (loading) return;
    fetchUserName();
    fetchUserHourlyRate();
  }, [user1, myDisplayName, loading, hourlyRate]);

  const [totalProjects, setTotalProjects] = useState([]);

  // Get Tasks
  const fetchUserTasks = async () => {
    try {
      const query = await db
        .collection('Task')
        .where('uid', '==', user1?.uid)
        .limit(3)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tempData.push(data);
          });
          setUsersTasks(tempData);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchUserTasks();
  }, [user1, usersTasks]);

  const [projectList, setProjectList] = useState([]);

  // User Total Projects
  const fetchProjectList = async () => {
    try {
      const query = await db
        .collection('Projects')
        .where('uid', '==', user1?.uid)
        .get()
        .then((snapshot) => {
          const tempData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            tempData.push(data);
          });
          setProjectList(tempData);
        });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchProjectList();
  }, [user1, projectList]);

  const [loadingWheel, isLoadingWheel] = useState(false);
  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  useEffect(async () => {
    isLoadingWheel(true);
    await sleep(3500);
    isLoadingWheel(false);
  }, [isLoadingWheel]);

  const sum = usersTasks.reduce((sum, { hour }) => sum + hour, 0);

  const [totalToggl, setTotalToggl] = useState(0);
  const [tasksToggl, setTasksToggl] = useState(0);
  const getTogglHours = () => {
    let encoded = base64_encode('6f6f0a2e5f220c1b4a9c1a783cc4b627:api_token');
    let startdate = moment().subtract(1, 'weeks').startOf('month').format('YYYY-MM-DD');
    let enddate = moment().subtract(1, 'weeks').endOf('month').format('YYYY-MM-DD');

    // let encodedUri = encodeURI(uri);
    fetch(`https://api.track.toggl.com/api/v9/me/time_entries?start_date=${startdate}&end_date=${enddate}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Basic ${encoded}`,
      },
    })
      .then((resp) => resp.json())
      .then((json) => {
        // setTotalToggl(json)
        setTasksToggl(json.length);
        let sum = 0;

        json.forEach((value) => {
          sum += value.duration;
        });
        const roundUp = Math.round(sum / 3600);
        setTotalToggl(roundUp);
        // console.log(json,"JSON")
      })
      .catch((err) => console.error(err, 'ERROR'));
  };

  useEffect(() => {
    getTogglHours();
  }, []);

  useEffect(() => {}, []);

  return (
    <Page title="Dashboard">
      {loadingWheel != false ? (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <CircularProgress style={{ textAlign: 'center', alignItems: 'center' }} />
          </div>
        </>
      ) : (
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4">
              {' '}
              <p>Welcome, {myDisplayName}</p>
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
              <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                New Time
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: 800 }}>
                  <TimeModal />
                </Box>
              </Modal>
              &nbsp; &nbsp;
              {myDisplayName === 'Tom Galland' ? (
                <>
                  <div></div>
                </>
              ) : (
                <>
                  <Button variant="contained" onClick={handleOpenInvoice} startIcon={<Iconify icon="eva:plus-fill" />}>
                    New Invoice
                  </Button>
                  <Modal
                    open={openinvoice}
                    onClose={handleCloseInvoice}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={{ ...style, width: 800 }}>
                      <InvoiceModal />
                    </Box>
                  </Modal>
                </>
              )}
            </Stack>
          </Stack>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Monthly Income"
                totalDollar="$"
                total={totalToggl * hourlyRate}
                icon="arcticons:money-manager"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title="Total Hours" total={totalToggl} color="info" icon="arcticons:pop-time" />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Projects"
                total={projectList?.length}
                color="warning"
                icon="octicon:project-24"
              />
            </Grid>*/}

            <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary title="Tasks" total={tasksToggl} color="error" icon="fluent:shield-task-28-regular" />
            </Grid>

            {/* <Grid item xs={12} md={6} lg={6}>
              <Card>
                <CardHeader title={'Latest Tasks'} />

                <CardContent
                  sx={{
                    '& .MuiTimelineItem-missingOppositeContent:before': {
                      display: 'none',
                    },
                  }}
                >
                  <Timeline>
                    {usersTasks.map((user, e, index) => {
                      return (
                        <TimelineItem key={user.dataid} value={user.project}>
                          <TimelineSeparator>
                            <TimelineDot color="primary" />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                              {user.project}
                            </Typography>
                            <Typography variant="subtitle2" style={{ fontWeight: 'light' }}>
                              {' '}
                              {user.hour} hours
                            </Typography>

                            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                              {user.date}
                            </Typography>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })}
                  </Timeline>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Card>
                <CardHeader title={'Current Clients'} />

                <CardContent
                  sx={{
                    '& .MuiTimelineItem-missingOppositeContent:before': {
                      display: 'none',
                    },
                  }}
                >
                  <Timeline
                    onClick={() => {
                      navigate('/clients');
                    }}
                  >
                    {projectList.map((projects, e, index) => {
                      return (
                        <Button
                          style={{ justifyContent: 'flex-start' }}
                          key={projects.dataid}
                          value={projects.name}
                          onClick={() => {
                            navigate('/clients');
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            style={{ fontWeight: 'bold', paddingBottom: '0px', textAlign: 'left' }}
                          >
                            {projects.name}
                          </Typography>
                        </Button>
                      );
                    })}
                  </Timeline>
                  <div style={{ textAlign: 'center', paddingTop: '30px' }}>
                    <Button
                      variant="contained"
                      fullWidth
                      style={{ textAlign: 'center', alignItems: 'center' }}
                      onClick={handleOpenProject}
                      startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                      New Client
                    </Button>
                    <Modal
                      open={openNewProject}
                      onClose={handleCloseProject}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={{ ...style, width: 800 }}>
                        <NewProjectModal />
                      </Box>
                    </Modal>
                  </div>
                </CardContent>
              </Card>
            </Grid> */}
          </Grid>
        </Container>
      )}
    </Page>
  );
}
