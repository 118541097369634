import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, deleteClient, getClient, logout, updateClient } from "src/helpers/firebase";

// @mui
import { Table, Form, InputGroup, ButtonGroup } from "react-bootstrap";
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Snackbar, Stack, Modal, Box, Card, CardContent, CardHeader, InputLabel, Select, MenuItem, TextField, Alert, FormControl, } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Colxx, Separator } from 'src/components/common/CustomBootstrap';
import {
    UncontrolledDropdown,
    DropdownItem,
    DropdownToggle,
    DropdownMenu, FormGroup, Row, CardBody, List, Collapse, Input, Spinner,
} from 'reactstrap';
import Label from 'src/components/Label';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import date from 'date-and-time';

// sections
import {
    AppClients,
    AppNewsUpdate,
    AppOrderTimeline,
    AppCurrentVisits,
    AppWebsiteVisits,
    AppTrafficBySite,
    AppWidgetSummary,
    AppCurrentSubject,
    AppConversionRates,
    Timer,
    TimeModal,
} from '../sections/@dashboard/app';
import { LoadingButton } from '@mui/lab';
import { useAuthState } from 'react-firebase-hooks/auth';
import ClientDataService from "./client.service";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    borderRadius: '25px'
};

export const ClientPage = ({ match }) => {
    const [user1, loading] = useAuthState(auth);
    const [clientId, setClientId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [streetAddress, setStreetAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [project, setProject] = useState("");
    const [client, setClient] = useState("")
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [flag, setFlag] = useState(true);
    const [message, setMessage] = useState({ error: false, msg: "" });
    const now = new Date();
    const todaysdatetime = date.format(now, 'DD MMM YYYY HH:mm:ss A ');
    const [newDocId, setNewDocId] = useState('');
    const docid = db.collection('Projects').doc().id;
    const [user] = useAuthState(auth);
    const [projectList, setProjectList] = useState([])

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))


    const getClientIdHandler = (id) => {
        handleOpen();
        console.log("The ID of document to be edited: ", id);
        setClientId(id);
    };

    const [clients, setClients] = useState([]);
    useEffect(() => {
        getClients();
    }, []);

    const getClients = async () => {
        const data = await ClientDataService.getAllClients();
        console.log(data.docs);
        setClients(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };

    const deleteHandler = async (id) => {
        await ClientDataService.deleteClient(id);
        getClients();
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage("");
        const newClient = {
            name,
            email,
            project,
            client,
            streetAddress,
            city,
            country,
            postcode,
            date: todaysdatetime,
            dataid: docid,
            uid: user.uid,
        };
        console.log(newClient);

        try {
            if (clientId !== undefined && clientId !== "") {
                await ClientDataService.updateClient(clientId, newClient);
                setClientId("");
                setMessage({ error: false, msg: "Updated successfully!" });
            } else {
                await ClientDataService.addClients(newClient);
                setMessage({ error: false, msg: "New Client added successfully!" });
            }
        } catch (err) {
            setMessage({ error: true, msg: err.message });
        }

        setName("");
        setEmail("");
        setProject("");
        setClient("");
        setStreetAddress("");
        setCity("");
        setCountry("");
        setPostcode("")
        handleClose();
        getClients();
        await sleep(3000);
        setMessage("");
    };

    const editHandler = async () => {
        setMessage("");
        try {
            const docSnap = await ClientDataService.getClient(clientId);
            console.log("the record is :", docSnap.data());
            setName(docSnap.data().name);
            setEmail(docSnap.data().email);
            setStreetAddress(docSnap.data().streetAddress);
            setCity(docSnap.data().city);
            setCountry(docSnap.data().country);
            setPostcode(docSnap.data().postcode);
        } catch (err) {
            setMessage({ error: true, msg: err.message });
        }
        getClients();
    };

    useEffect(() => {
        console.log("The id here is : ", clientId);
        if (clientId !== undefined && clientId !== "") {
            editHandler();
        }
    }, [clientId]);


    // User Total Projects
    const fetchTotalProjects = async () => {
        try {
            const query = await db
                .collection("Projects")
                .where("uid", "==", user?.uid)
                .get()
                .then((snapshot) => {
                    const tempData = [];
                    snapshot.forEach((doc) => {

                        const data = doc.data();
                        tempData.push(data);
                    });
                    setProjectList(tempData);
                });
            // const data = await query.docs[0].data();
            // setProjectList(data);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTotalProjects();
    }, [user, projectList]);

    const clientsSorted =
        clients.sort((date1, date2) => {
            if (date1.date > date2.date) return 1;
            if (date1.date < date2.date) return -1;
        }).reverse();

    return (
        <>
            <Page title="Dashboard">
                <Container maxWidth="xl">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>

                        <Typography variant="h4" sx={{ mb: 5 }}>
                            Clients
                        </Typography>
                        <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
                            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                                New Client
                            </Button>
                            <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                            >
                                <Box sx={{ ...style, width: 800 }}>
                                    <Form onSubmit={handleSubmit}>
                                        <Stack spacing={1}>
                                            <TextField
                                                type="text"
                                                placeholder="Name"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />
                                            <TextField
                                                type="text"
                                                placeholder="Email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />

                                            <TextField
                                                placeholder="Street Address"
                                                type="text"
                                                value={streetAddress}
                                                fullWidth
                                                onChange={(event) => setStreetAddress(event.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />

                                            <TextField
                                                placeholder="City"
                                                type="text"
                                                value={city}
                                                fullWidth
                                                onChange={(event) => setCity(event.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />
                                            <TextField
                                                placeholder="Country"
                                                type="text"
                                                value={country}
                                                fullWidth
                                                onChange={(event) => setCountry(event.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />

                                            <TextField
                                                placeholder="Postcode"
                                                type="text"
                                                value={postcode}
                                                fullWidth
                                                onChange={(event) => setPostcode(event.target.value)}
                                                style={{
                                                    border: 'none',
                                                    backgroundColor: 'white',
                                                    height: '60px',
                                                    fontSize: '30px',
                                                }}
                                            />

                                            <div className="d-grid gap-2" style={{ textAlign: 'center' }}>
                                                <Button variant="contained" type="Submit" style={{ width: 200, alignItems: "center" }}>
                                                    Save
                                                </Button>
                                            </div>
                                        </Stack>
                                    </Form>
                                </Box>
                            </Modal>
                        </Stack>
                    </Stack>
                    <Stack alignItems="center" justifyContent="center" mb={5}>
                        {message?.msg && (
                            <Alert
                                variant={message?.error ? "danger" : "success"}
                                dismissible
                                onClose={() => setMessage("")}
                            >
                                {message?.msg}
                            </Alert>
                        )}
                    </Stack>
                    <Grid container spacing={0}>
                        {clientsSorted.map((doc, index) => {
                            if (doc.uid === user1.uid)
                                return (
                                    <Stack justifyContent="space-evenly" style={{ padding: "10px" }}>
                                        <Stack justifyContent="space-evenly">
                                            <Card style={{ margin: "0px", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "10px", width: "200px", textAlign: "left" }} key={doc.docId} justifyContent="space-evenly" >

                                                <Stack direction="column" justifyContent="space-evely"  >
                                                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.name}</Typography>
                                                    <Typography variant="subtitle1" style={{ fontSize: "10px" }}>{doc.email}</Typography>
                                                    <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: "10px" }}>
                                                        <Button variant="contained" onClick={(e) => getClientIdHandler(doc.id)}>
                                                            Edit
                                                        </Button>
                                                        <Button variant='contained' style={{ backgroundColor: "red" }} onClick={(e) => deleteHandler(doc.id)}>
                                                            Delete
                                                        </Button>
                                                    </Stack>
                                                </Stack>
                                            </Card>
                                        </Stack>
                                    </Stack>
                                );
                            return (<>
                            </>);
                        })}
                    </Grid>

                </Container>
            </Page >
        </>
    );
};
export default ClientPage;
