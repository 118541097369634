import React, { useState, Fragment, useEffect } from 'react';
import { Grid, Container, Typography, Button, Snackbar, Stack, Modal, Box, Card, CardContent, CardHeader, InputLabel, Select, MenuItem, TextField, Alert, FormControl, } from '@mui/material';
import InvoiceDataService from './invoice.service';
import axios from 'axios';


export default function AllInvoices() {

    const [allInvoices, setAllInvoices] = useState();

    const getEveryInvoices = async () => {
        const data = await InvoiceDataService.getAllInvoices()
        console.log(data, "INVOICES",)
        setAllInvoices(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        // setAllInvoices(invoices)

    }

    useEffect(() => {
        if (!allInvoices) getEveryInvoices()
        if (allInvoices) console.log(allInvoices, "ALL INVOICES")
    }, [])

    const downloadInvoice = (id) => {
        console.log(id, "INVOICE ID")

    }

    return (
        <>
            <div style={{ marginTop: "50px" }}>
                <Typography variant="subtitle1" style={{ fontWeight: 'bold', fontSize: '20px' }}>
                    All Invoices
                </Typography>
                <Grid container spacing={0}>
                    {allInvoices && allInvoices.map((doc, index) => {
                        return (
                            <Stack justifyContent="space-evenly" style={{ padding: "10px" }}>
                                <Stack justifyContent="space-evenly">
                                    <Card style={{ margin: "0px", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "10px", width: "200px", textAlign: "left" }} key={doc.docId} justifyContent="space-evenly" >

                                        <Stack direction="column" justifyContent="space-evely"  >
                                            <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>{doc.name}</Typography>
                                            <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: "10px" }}>
                                                <Button variant="contained" href={doc.url} onClick={() => axios.get(doc.url)}>
                                                    Download
                                                </Button>
                                            </Stack>
                                        </Stack>
                                    </Card>
                                </Stack>
                            </Stack>
                        );
                    })}
                </Grid>
            </div>
        </>
    );
}
