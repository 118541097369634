import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from 'firebase/firestore';
import { auth, db, deleteUser, getUser, logout, updateUser } from 'src/helpers/firebase';

// @mui
import { Table, Form, InputGroup, ButtonGroup } from 'react-bootstrap';
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Button,
  Snackbar,
  Stack,
  Modal,
  Box,
  Card,
  CardContent,
  CardHeader,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Alert,
  FormControl,
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Colxx, Separator } from 'src/components/common/CustomBootstrap';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  FormGroup,
  Row,
  CardBody,
  List,
  Collapse,
  Input,
  Spinner,
} from 'reactstrap';
import Label from 'src/components/Label';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import date from 'date-and-time';

// sections
import {
  AppUsers,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
  Timer,
  TimeModal,
} from '../sections/@dashboard/app';
import { LoadingButton } from '@mui/lab';
import { useAuthState } from 'react-firebase-hooks/auth';
import UserDataService from './user.service';
import { createCheckoutSession } from 'src/stripe/createCheckoutSession';


const style = {
  marginTop: "30px",
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: 'scroll',
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '5px',
  height: "80%"
};

const premiumPrices = {
  monthly: 'price_1KzRCNA1o09nLBT7FvxdmVo9',
  annually: 'price_1KzRCNA1o09nLBT7otsYioEB',
};



export const User = ({ match }) => {
  const [user1, loading] = useAuthState(auth);
  const [userId, setUserId] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postcode, setPostcode] = useState('');
  const [bankAccount, setBankAccount] = useState('');
  const [bsb, setBsb] = useState('');
  const [bankName, setBankName] = useState('');
  const [hourlyRate, setHourlyRate] = useState(parseInt(0));
  const [abn, setAbn] = useState('');
  const [invoiceNumber, setInvoiceNumber] = useState('');
  const [trackingType, setTrackingType] = useState('');
  const [togglApiKey, setTogglApiKey] = useState(0);
  const [user, setUser] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [message, setMessage] = useState({ error: false, msg: '' });
  const now = new Date();
  const todaysdatetime = date.format(now, 'DD MMM YYYY HH:mm:ss A ');
  const [newDocId, setNewDocId] = useState('');
  const docid = db.collection('User').doc().id;
  const [projectList, setProjectList] = useState([]);

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getUserIdHandler = (id) => {
    handleOpen();
    console.log('The ID of document to be edited: ', id);
    setUserId(id);
  };

  const [users, setUsers] = useState([]);
  useEffect(() => {
    if (!users) getUsers();
  }, []);

  const getUsers = async () => {
    const data = await UserDataService.getAllUsers();
    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
  };

  const deleteHandler = async (id) => {
    await UserDataService.deleteUser(id);
    getUsers();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    const hourlyRateNumber = parseInt(hourlyRate);
    const newUser = {
      firstName,
      lastName,
      businessName,
      address,
      city,
      country,
      postcode,
      bankAccount,
      bsb,
      bankName,
      hourlyRate: hourlyRateNumber,
      invoiceNumber,
      trackingType,
      togglApiKey,
      abn,
      date: todaysdatetime,
      dataid: docid,
    };
    console.log(newUser);

    try {
      if (userId !== undefined && userId !== '') {
        await UserDataService.updateUser(userId, newUser);
        setUserId('');
        setMessage({ error: false, msg: 'Updated successfully!' });
      } else {
        await UserDataService.addUsers(newUser);
        setMessage({ error: false, msg: 'New User added successfully!' });
      }
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }

    setFirstName('');
    setLastName('');
    setBusinessName('');
    setAddress('');
    setCity('');
    setCountry('');
    setPostcode('');
    setBankAccount('');
    setBsb('');
    setBankName('');
    setHourlyRate(0);
    setInvoiceNumber(0);
    setTrackingType('');
    setTogglApiKey(0);
    setAbn('');
    handleClose();
    getUsers();
    await sleep(3000);
    setMessage('');
  };

  const editHandler = async () => {
    setMessage('');
    try {
      const docSnap = await UserDataService.getUser(userId);
      console.log('the record is :', docSnap.data());
      setFirstName(docSnap.data().firstName);
      setLastName(docSnap.data().lastName);
      setBusinessName(docSnap.data().businessName);
      setAddress(docSnap.data().address);
      setCity(docSnap.data().city);
      setCountry(docSnap.data().country);
      setPostcode(docSnap.data().postcode);
      setBankAccount(docSnap.data().bankAccount);
      setBsb(docSnap.data().bsb);
      setBankName(docSnap.data().bankName);
      setHourlyRate(docSnap.data().hourlyRate);
      setInvoiceNumber(docSnap.data().invoiceNumber);
      setTrackingType(docSnap.data().trackingType);
      setTogglApiKey(docSnap.data().togglAPI);
      setAbn(docSnap.data().abn);
    } catch (err) {
      setMessage({ error: true, msg: err.message });
    }
    getUsers();
  };

  useEffect(() => {
    if (userId !== undefined && userId !== '') {
      editHandler();
    }
  }, [userId]);

  const [sessionLoadingState, setSessionLoadingState] = useState({ monthly: false, annually: false });

  const handleCreateCheckoutSession = async (name, priceId) => {
    if (sessionLoadingState.monthly || sessionLoadingState.annually || !user1) {
      return;
    }
    setSessionLoadingState({
      ...sessionLoadingState,
      [name]: true,
    });
    await createCheckoutSession(user1, priceId);
    setSessionLoadingState({
      ...sessionLoadingState,
      [name]: false,
    });
  };

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" sx={{ mb: 5 }}>
              Users
            </Typography>
            <p>{trackingType}</p>
            <Stack direction="row" alignItems="center" justifyContent="end" mb={5}>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={{ ...style, width: 800 }}>
                  <Typography variant="h4" sx={{ mb: 5 }}>
                    Update your Details
                  </Typography>
                  <Form onSubmit={handleSubmit}>
                    <Stack spacing={1} fullWidth>
                      <TextField
                        placeholder="First Name"
                        type="text"
                        value={firstName}
                        fullWidth
                        onChange={(event) => setFirstName(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <TextField
                        placeholder="Last Name"
                        type="text"
                        value={lastName}
                        fullWidth
                        onChange={(event) => setLastName(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <TextField
                        placeholder="Business Name"
                        type="text"
                        value={businessName}
                        fullWidth
                        onChange={(event) => setBusinessName(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <Stack spacing={0} fullWidth>
                        <Typography>Address</Typography>
                        <TextField
                          placeholder="Street"
                          type="text"
                          value={address}
                          fullWidth
                          onChange={(event) => setAddress(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          placeholder="City"
                          type="text"
                          value={city}
                          fullWidth
                          onChange={(event) => setCity(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          placeholder="Country"
                          type="text"
                          value={country}
                          fullWidth
                          onChange={(event) => setCountry(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          placeholder="Postcode"
                          type="text"
                          value={postcode}
                          fullWidth
                          onChange={(event) => setPostcode(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                      </Stack>
                      <Stack spacing={0} fullWidth>
                        <Typography>Bank Details</Typography>
                        <TextField
                          placeholder="Bank Name"
                          type="text"
                          value={bankName}
                          fullWidth
                          onChange={(event) => setBankName(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          placeholder="Account Number"
                          type="text"
                          value={bankAccount}
                          fullWidth
                          onChange={(event) => setBankAccount(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                        <TextField
                          placeholder="BSB"
                          type="text"
                          value={bsb}
                          fullWidth
                          onChange={(event) => setBsb(event.target.value)}
                          style={{
                            border: 'none',
                            backgroundColor: 'white',
                            height: '60px',
                            fontSize: '30px',
                          }}
                        />
                      </Stack>

                      <TextField
                        placeholder="ABN"
                        type="text"
                        value={abn}
                        fullWidth
                        onChange={(event) => setAbn(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <Typography>Hourly Rate</Typography>
                      <TextField
                        placeholder="Hourly Rate"
                        type="number"
                        value={hourlyRate}
                        fullWidth
                        onChange={(event) => setHourlyRate(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <Typography>Invoice Number</Typography>
                      <TextField
                        placeholder="Invoice Number"
                        type="number"
                        value={invoiceNumber}
                        fullWidth
                        onChange={(event) => setInvoiceNumber(event.target.value)}
                        style={{
                          border: 'none',
                          backgroundColor: 'white',
                          height: '60px',
                          fontSize: '30px',
                        }}
                      />
                      <FormControl style={{ width: '500px' }}>
                        <InputLabel>Tracking Type</InputLabel>
                        <Select
                          id="demo-simple-select"
                          label="Age"
                          defaultValue={trackingType}
                          onChange={(event) => {
                            event.preventDefault();
                            setTrackingType(event.target.value);
                          }}
                        >
                          <MenuItem
                            className="church-select-item"
                            key="toggl"
                            id="toggl"
                            value="Toggl"
                            defaultValue=" Toggl"
                          >
                            Toggl
                          </MenuItem>
                          <MenuItem
                            className="church-select-item"
                            key="manual"
                            id="manual"
                            value="manual"
                            defaultValue=" Manual"
                          >
                            Manual
                          </MenuItem>
                        </Select>
                      </FormControl>
                      {trackingType == "Toggl" ? (
                        <>
                          <Typography>Toggl API Key</Typography>
                          <TextField
                            placeholder="Toggl Api Key"
                            type="number"
                            value={togglApiKey}
                            fullWidth
                            onChange={(event) => setTogglApiKey(event.target.value)}
                            style={{
                              border: 'none',
                              backgroundColor: 'white',
                              height: '60px',
                              fontSize: '30px',
                            }}
                          /></>
                      ) : (
                        <></>
                      )}


                      <div className="d-grid gap-2" style={{ textAlign: 'center' }}>
                        <Button variant="contained" type="Submit" style={{ width: 200, alignItems: 'center' }}>
                          Update Profile
                        </Button>
                      </div>
                    </Stack>
                  </Form>
                </Box>
              </Modal>
            </Stack>
          </Stack>
          <Stack alignItems="center" justifyContent="center" mb={5}>
            {message?.msg && (
              <Alert variant={message?.error ? 'danger' : 'success'} dismissible onClose={() => setMessage('')}>
                {message?.msg}
              </Alert>
            )}
          </Stack>
          <Grid container spacing={0}>
            {/* <Stack justifyContent="space-evenly" style={{ padding: "10px" }}>
              <Stack justifyContent="space-evenly">
                <Card style={{ margin: "0px", paddingTop: "25px", paddingBottom: "25px", paddingLeft: "20px", paddingRight: "10px", width: "200px", textAlign: "left" }} justifyContent="space-evenly" >

                  {/* <Stack direction="column" justifyContent="space-evely"  >
                    <Typography variant="subtitle1" style={{ fontWeight: "bold" }}>
                      Upgrade to Ultimate
                    </Typography>


                    <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: "10px" }}>
                      <Button variant="contained" onClick={({ target }) => createCheckoutSession(user1)}>
                        Upgrade to Ultimate
                      </Button>
                    </Stack>
                  </Stack> 
            <div className="button" style={{ textAlign: "center", color: "#3a4759", }}>
              <Button
                color="primary"
                className="btn btn-success btn-2"
                name='monthly'
                style={{ textAlign: "center", width: "200px", }}
                disabled={sessionLoadingState.monthly || sessionLoadingState.annually}
                onClick={({ target }) => handleCreateCheckoutSession(target.name, premiumPrices.monthly)}
              >
                {sessionLoadingState.monthly ? <Spinner size='sm' /> : 'Get Started'}
              </Button>
            </div>
          </Card>
        </Stack>
      </Stack> */}
            {users.map((doc, index) => {
              if (doc.uid === user1.uid)
                return (
                  <Stack justifyContent="space-evenly" style={{ padding: '10px' }}>
                    <Stack justifyContent="space-evenly">
                      <Card
                        style={{
                          margin: '0px',
                          paddingTop: '25px',
                          paddingBottom: '25px',
                          paddingLeft: '20px',
                          paddingRight: '10px',
                          width: '200px',
                          textAlign: 'left',
                        }}
                        key={doc.docId}
                        justifyContent="space-evenly"
                      >
                        <Stack direction="column" justifyContent="space-evely">
                          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                            {doc.firstName}
                          </Typography>
                          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                            {doc.lastName}
                          </Typography>

                          <Stack direction="row" mb={0} justifyContent="space-evenly" style={{ paddingTop: '10px' }}>
                            <Button variant="contained" onClick={(e) => getUserIdHandler(doc.id)}>
                              Edit Profile
                            </Button>
                          </Stack>
                        </Stack>
                      </Card>
                    </Stack>
                  </Stack>
                );
              return <></>;
            })}
          </Grid>
        </Container>
      </Page>
    </>
  );
};
export default User;
