import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, deleteTask, getTask, logout, updateTask } from "src/helpers/firebase";

// @mui
import { Table, Form, InputGroup, ButtonGroup } from "react-bootstrap";
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Snackbar, Stack, Modal, Box, Card, CardContent, CardHeader, InputLabel, Select, MenuItem, TextField, Alert, FormControl, } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { Colxx, Separator } from 'src/components/common/CustomBootstrap';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu, FormGroup, Row, CardBody, List, Collapse, Input, Spinner,
} from 'reactstrap';
import Label from 'src/components/Label';
// components
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import date from 'date-and-time';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { boardService } from "./service/board"
import { BoardsPageSkeleton } from './components/BoardsPageSkeleton';
import { BoardTitle } from './components/BoardTitle';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: '25px'
};

export const ProjectTask = ({ match }) => {
  const [boards, setBoards] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    (async () => {
      await fetchBoards();
    })();
  }, []);

  const fetchBoards = async () => {
    await boardService.userBoards().on('value', (snapshot) => {
      if (!snapshot) {
        return;
      }
      setBoards(objectToArray(snapshot.val() || {}));
      setLoading(false);
    });
  };

  const addBoard = async (board) => {
    await boardService.addBoard(board);
    setModalVisible(false);
  };

  const starBoard = async (board, starred) => {
    await boardService.updateBoard(board, { starred });
  };

  const objectToArray = (data) =>
    !data
      ? []
      : Object.values(data).map((value, index) => ({
        ...value,
        key: Object.keys(data)[index],
      }));

  if (loading) {
    return <BoardsPageSkeleton count={4} />;
  }

  const starredBoards = boards.filter((board) => board.starred);

  return (
    <>
      <Page title="Dashboard">
        <Container maxWidth="xl">
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            {!!starredBoards.length && (
              <>
                <div className="flex mb-3 items-center text-xl">
                  Starred Boards
                </div>

                <div className="grid grid-cols-4 gap-4 mb-6">
                  {starredBoards.map((board) => (
                    <BoardTitle
                      key={board?.key}
                      title={board.title}
                      handleBoardClick={() => navigate(`boards/${board?.key}`)}
                      handleBoardStarToggling={() =>
                        starBoard(board?.key, !board.starred)
                      }
                      starred={board.starred}
                    />
                  ))}
                </div>
              </>
            )}
          </Stack>
        </Container>
      </Page >
    </>
  );
};
export default ProjectTask;
