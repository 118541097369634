import React, { useState, Fragment, useEffect } from 'react';
import { query, collection, getDocs, where } from "firebase/firestore";
import { auth, db, logout } from "src/helpers/firebase";
import { Grid, Container, Typography, Button, Stack, TextField, Box, Card, CardContent, CardHeader, InputLabel, Select, MenuItem, FormControl, } from '@mui/material';
import { useFormik, Form, FormikProvider } from 'formik';
import date from 'date-and-time';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";

import Page from "src/components/Page"
import Iconify from 'src/components/Iconify';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import { setDefaultLocale } from 'react-datepicker';

// ----------------------------------------------------------------------

export default function QuoteDetails() {
    // Current User
    const [user1, loading] = useAuthState(auth);
    const navigate = useNavigate();
    const [blankStatement, setBlankStatement] = useState("")

    const now = new Date();
    const todaysdatetime = date.format(now, 'DD MMM YYYY');

    const later = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
    const duedatetime = date.format(later, 'DD MMM YYYY');



    const formik = useFormik({
        initialValues: {
            hour: "",
            minute: "",
            seconds: "",
            project: "",
            task: "",
        },
        onSubmit: async () => {

        },
    });

    const { } = formik;

    // Get Users Business Details
    const [userBusinessDetails, setUserBusinessDetails] = useState([]);
    const usersCollectionRef = collection(db, "user");



    // Get Tasks
    const [tasks, setTasks] = useState([]);
    const usersCollectionRefTask = collection(db, "Task");



    // Current User
    const [clients, setClients] = useState("");
    const [lastName, setLastName] = useState("");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [postcode, setPostcode] = useState("");
    const [bankAccount, setBankAccount] = useState("");
    const [bsb, setBsb] = useState("");
    const [bankName, setBankName] = useState("");
    const [hourlyRate, setHourlyRate] = useState("");
    const [abn, setAbn] = useState("");
    const [docid, setDocId] = useState("")

    const fetchClients = async () => {
        try {
            const query = await db
                .collection("Projects")
                .where("uid", "==", user1?.uid)
                .where("name", "==", project)
                .get();
            const data = await query.docs[0].data();
            setDocId(data.docid)
            setClients(data.name);
            setAddress(data.businessAddress)
            setAbn(data.abn)
        } catch (err) {
            console.error(err);
        }
    };

    const fetchInvoiceNumber = async () => {
        try {
            const query = await db
                .collection("user")
                .where("uid", "==", user1?.uid)
                .get();
            const data = await query.docs[0].data();
            setInvoiceNumber(data.invoiceNumber)
        } catch (err) {
            console.error(err);
        }
    };



    // User Total Projects
    const [projectList, setProjectList] = useState([])
    const [project, setProject] = useState("");
    const fetchTotalProjects = async () => {
        try {
            const query = await db
                .collection("Projects")
                .where("uid", "==", user1?.uid)
                .get()
                .then((snapshot) => {
                    const tempData = [];
                    snapshot.forEach((doc) => {

                        const data = doc.data();
                        tempData.push(data);
                    });
                    setProjectList(tempData);
                });
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchTotalProjects();
    })



    const [invoiceTask, setInvoiceTask] = useState([])
    const [totalHours, setTotalHours] = useState([])

    // User Tasks Not Invoiced
    const fetchInvoiceTask = async () => {
        try {
            const query = await db
                .collection("Task")
                .where("uid", "==", user1?.uid)
                .where("project", "==", project)
                .get()
                .then((snapshot) => {
                    const tempData = [];
                    snapshot.forEach((doc) => {

                        const data = doc.data();
                        tempData.push(data);
                    });
                    setInvoiceTask(tempData);
                });
        } catch (err) {
            console.error(err);
        }
    };



    // Fetch Hourly Rate
    const [userHourlyRate, setUserHourlyRate] = useState([])
    const [latestInvoiceNumber, setLatestInvoiceNumber] = useState([])
    const fetchUserHourlyRate = async () => {
        try {
            const query = await db
                .collection("user")
                .where("uid", "==", user1?.uid)
                .get()
                .then((snapshot) => {
                    const tempData = [];
                    snapshot.forEach((doc) => {

                        const data = doc.data();
                        tempData.push(data);
                    });
                    setUserHourlyRate(tempData);
                    setLatestInvoiceNumber(tempData.invoiceNumber)
                });
        } catch (err) {
            console.error(err);
        }
    };




    const container = React.useRef(null);
    const pdfExportComponent = React.useRef(null);

    const exportPDFWithComponent = () => {
        if (pdfExportComponent.current) {
            pdfExportComponent.current.save();
        }
    };
    const [data, setData] = useState(false)

    const [currency, setCurrency] = useState([])
    const [invoiceNumber, setInvoiceNumber] = useState([])

    const fetchAll = () => {
        setData(true)
        const getUserBusinessDetails = async () => {
            const data = await getDocs(usersCollectionRef);
            setUserBusinessDetails(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getUserBusinessDetails();
        const getTasks = async () => {
            const data = await getDocs(usersCollectionRefTask);
            setTasks(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
        };
        getTasks();
        fetchClients();
        fetchTotalProjects();
        fetchInvoiceTask();
        fetchUserHourlyRate();
        fetchInvoiceNumber();
        // Get Total Sum Amount
        let newTotalHoursMap = invoiceTask.map((e) => { if (e.uid === user1.uid) return (e.hour) });
        setTotalHours(newTotalHoursMap.reduce((partialSum, a) => partialSum + a, 0))

        // Get Currency
        let newCurrency = userBusinessDetails.map((e) => { if (e.uid === user1.uid) return (e.currency) });
        setCurrency(newCurrency)

        // // Get Invoice Number
        // let newInvoiceNumber = userBusinessDetails.map((e) => { if (e.uid === user1.uid) return (e.invoiceNumber) });
        // setInvoiceNumber(newInvoiceNumber)

        // Get Hourly Rate
        let newHourlyRateMap = userHourlyRate.map((e) => { if (e.uid === user1.uid) return (e.hourlyRate) });
        setHourlyRate(newHourlyRateMap)
    }

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const fetchAllTasks = async () => {
        fetchAll();
        await sleep(3500);
        fetchAll();
    }

    useEffect(() => {
        fetchAllTasks()
        fetchAll()
    }, [invoiceNumber])


    return (
        <>
            <div>
                <div className="example-config">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                        <FormControl style={{ width: "500px" }}>
                            <InputLabel >Project</InputLabel>
                            <Select
                                id="demo-simple-select"
                                label="Age"
                                defaultValue=""
                                onChange={(event) => {
                                    event.preventDefault();
                                    setProject(event.target.value)
                                }}
                            >
                                {projectList.map((user, e, index) => {
                                    return (
                                        <MenuItem
                                            className="church-select-item"
                                            key={user.name}
                                            id="category"
                                            value={user.name}
                                            defaultValue=" your Project"
                                        >
                                            {user.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                        <Stack direction="row" alignItems="center" justifyContent="end" mb={0}>

                            <Button
                                variant="contained"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={() => { fetchAllTasks() }}
                                style={{ marginRight: 20 }}
                            >
                                Generate Quote
                            </Button>
                            <Button
                                variant="contained"
                                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                                onClick={exportPDFWithComponent}
                            >
                                Export to PDF
                            </Button>
                        </Stack>
                    </Stack>

                </div>
                {data === false ? (
                    <>
                    </>
                ) : (
                    <Stack direction={{ xs: 'column', sm: 'column' }} style={{ paddingLeft: "10px", paddingRight: "20px" }} justifyContent="space-between" spacing={2}>
                        <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "20px" }}>Tasks To Be Quoted</Typography>
                        {invoiceTask.map((task, e, index) => {
                            if (task.invoiced == false)
                                return (
                                    <>
                                        <Typography variant="subtitle1" style={{ fontSize: "15px" }}>- {task.task}</Typography>
                                    </>
                                );
                        })}
                        &nbsp;
                        &nbsp;
                    </Stack>
                )}
                <div style={{}} className="border rounded p-2">
                    <PDFExport
                        ref={pdfExportComponent}
                        paperSize="A4"
                        margin={20}
                        fileName={`Quote ${invoiceNumber} - ${clients}`}
                    >
                        <div ref={container}>
                            <Card style={{ padding: "20px" }}>
                                <FormikProvider value={formik} >
                                    <Form autoComplete="off" >

                                        <Stack spacing={1}>
                                            <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "15px" }}>Quote</Typography>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>

                                                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>

                                                    {userBusinessDetails.map((business, e, index) => {
                                                        if (business.uid === user1.uid)
                                                            return (
                                                                <>
                                                                    <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "10px" }}>{business.businessName}</Typography>
                                                                    <Typography variant="body1" style={{ fontSize: "10px" }} >{business.address}</Typography>
                                                                    <Typography variant="body1" style={{ fontSize: "10px" }}>{business.city}</Typography>
                                                                    <Typography variant="body1" style={{ fontSize: "10px" }}>{business.country}</Typography>
                                                                    <Typography variant="body1" style={{ fontSize: "10px" }}>{business.postcode}</Typography>
                                                                    <Typography variant="subtitbody1le1" style={{ fontSize: "10px" }}>ABN: {business.abn}</Typography>
                                                                </>
                                                            );
                                                    })}

                                                </Stack>
                                                <div style={{ alignItems: "end" }}>
                                                    <h1 ></h1>
                                                </div>
                                                <div style={{ alignItems: "end" }}>
                                                    <Typography variant="subtitle1" style={{ textAlign: "right", fontSize: "10px" }}>{currency} ${totalHours * hourlyRate * 1.10}</Typography>
                                                    <Typography variant="subtitle1" style={{ textAlign: "right", fontSize: "10px", fontWeight: "bold", paddingRight: "2px" }}>Quote
                                                        {invoiceNumber}
                                                    </Typography>
                                                    <Typography style={{ textAlign: "right", fontSize: "10px" }}>Issued: 15th April 2022</Typography>
                                                </div>
                                            </Stack>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" style={{ paddingBottom: "10px", paddingTop: "10px" }} spacing={2}>
                                                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                                                    <p style={{ fontSize: "10px", }}>Quote For:</p>
                                                    <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "10px" }}>{clients}</Typography>
                                                    <Typography variant="subtitle1" style={{ fontSize: "10px" }}>{address}</Typography>
                                                    <Typography variant="subtitle1" style={{ fontSize: "10px" }}>ABN: {abn}</Typography>
                                                </Stack>

                                            </Stack>
                                            <Stack direction={{ xs: 'column', sm: 'row' }} style={{ padding: "10px", paddingTop: "10px", borderRadius: "5px", color: "white", backgroundColor: "grey" }} justifyContent="space-between" spacing={2}>
                                                <p style={{ width: "250px", fontSize: "10px" }} >Description</p>
                                                <p style={{ fontSize: "10px" }}>Quantity</p>
                                                <p style={{ fontSize: "10px", paddingRight: "10px" }}>Price</p>
                                            </Stack>
                                            <Stack direction={{ xs: 'column', sm: 'column' }} style={{ paddingLeft: "10px", paddingRight: "20px" }} justifyContent="space-between" spacing={2}>
                                                {invoiceTask.map((task, e, index) => {
                                                    if (task.invoiced == false)
                                                        return (
                                                            <>
                                                                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                                                    <Stack direction={{ xs: 'column', sm: 'column' }} spacing={0}>
                                                                        <Typography variant="subtitle1" style={{ width: "200px", fontSize: "10px", fontWeight: "bold" }}>{task.task}</Typography>
                                                                        <Typography variant="body1" style={{ width: "250px", fontSize: "10px", }}>{task.description}</Typography>
                                                                    </Stack>
                                                                    <Typography variant="subtitle1" style={{ fontSize: "10px", paddingRight: "0px" }}>1</Typography>
                                                                    <Typography variant="subtitle1" style={{ fontSize: "10px", paddingLeft: "0px" }}>{task.hour * hourlyRate}</Typography>
                                                                </Stack>
                                                            </>
                                                        );
                                                })}
                                            </Stack>

                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="end" style={{ paddingTop: "10px" }} spacing={2}>
                                                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={2}>
                                                </Stack>
                                                <Stack direction={{ xs: 'column', sm: 'column' }} style={{ paddingRight: "20px" }} spacing={2}>
                                                    <>
                                                        <Stack direction={{ xs: 'column', sm: 'column' }} justifyContent="space-between" spacing={0}>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={0}>
                                                            </Stack>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                                                <Typography variant="subtitle1" style={{ fontSize: "10px" }}>Subtotal</Typography>
                                                                <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "10px", color: "black" }}>{currency} ${totalHours * hourlyRate}</Typography>
                                                            </Stack>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                                                <Typography variant="subtitle1" style={{ fontSize: "10px" }}>GST</Typography>
                                                                <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "10px", color: "black" }}>{currency} ${totalHours * hourlyRate / 100 * 10}</Typography>
                                                            </Stack>
                                                            <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between" spacing={2}>
                                                                <Typography variant="subtitle1" style={{ fontSize: "10px" }}>Total</Typography>
                                                                <Typography variant="subtitle1" style={{ fontWeight: "bold", fontSize: "10px", color: "black" }}>{currency} ${totalHours * hourlyRate * 1.10}</Typography>
                                                            </Stack>
                                                        </Stack>
                                                    </>
                                                </Stack>
                                            </Stack>
                                            <p style={{ fontSize: "10px", fontWeight: "bold" }}>Notes</p>
                                            <Stack direction={{ xs: 'column', sm: 'column' }} style={{ width: "250px" }} spacing={0}>
                                                <Typography variant="body1" style={{ fontSize: "10px" }}>This web development agreement shall be deemed effective as of 15/05/2022 by and between the following
                                                    parties:</Typography>
                                                <Typography variant="body1" style={{ fontSize: "10px" }}>
                                                    Tom Galland (Developer) and
                                                </Typography>
                                                <Typography variant="body1" style={{ fontSize: "10px" }}>

                                                    MRD Partners (Company).</Typography>
                                                &nbsp;
                                                &nbsp;
                                                {userBusinessDetails.map((business, e, index) => {
                                                    return (
                                                        <>
                                                            <Typography variant="body1" style={{ fontSize: "10px" }}>{business.bankName}</Typography>
                                                            <Typography variant="body1" style={{ fontSize: "10px" }} >{business.bankAccountName}</Typography>
                                                            <Typography variant="body1" style={{ fontSize: "10px" }} >{business.bankAccount}</Typography>
                                                            <Typography variant="body1" style={{ fontSize: "10px" }}>{business.bsb}</Typography>
                                                        </>
                                                    );
                                                })}
                                            </Stack>
                                        </Stack>
                                    </Form>
                                </FormikProvider>
                            </Card>
                        </div>

                    </PDFExport>
                </div>
            </div>
        </>
    );
}
