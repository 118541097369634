import { auth, } from 'src/helpers/firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const userBoards = () => firebase.database().ref('user').child('boards');

const userBoard = (board) => userBoards().child(board);

const getBoard = (key) => userBoard(key).once('value');

const addBoard = (board) => userBoards().push(board);

const deleteBoard = (key) => userBoards().child(key).remove();

const updateBoard = (key, data) => userBoard(key).update(data);

export const boardService = {
    userBoards,
    getBoard,
    addBoard,
    deleteBoard,
    updateBoard,
};
