import { Container, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useState } from "react"
import { useAuthState } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';
import Page from "src/components/Page"
import QuoteDetails from './QuoteDetails';
import { auth, db, logout } from "src/helpers/firebase";

// ----------------------------------------------------------------------

export default function QuotePage() {

    return (
        <>
            <Page title="New Quote">
                <h1 style={{ textAlign: "center", alignItems: "center" }} className="center fs-30">New Quote</h1>
                <QuoteDetails />
            </Page>
        </>
    );
}
