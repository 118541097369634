import { db } from "src/helpers/firebase";

import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

const clientsCollectionRef = collection(db, "Projects");
class ClientDataService {
    addClients = (newClient) => {
        return addDoc(clientsCollectionRef, newClient);
    };

    updateClient = (id, updatedClient) => {
        const clientDoc = doc(db, "Projects", id);
        return updateDoc(clientDoc, updatedClient);
    };

    deleteClient = (id) => {
        const clientDoc = doc(db, "Projects", id);
        return deleteDoc(clientDoc);
    };

    getAllClients = () => {
        return getDocs(clientsCollectionRef);
    };

    getClient = (id) => {
        const clientDoc = doc(db, "Projects", id);
        return getDoc(clientDoc);
    };
}

export default new ClientDataService();
