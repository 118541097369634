import { db } from "src/helpers/firebase";

import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

const invoiceCollectionRef = collection(db, "invoices");
class InvoiceDataService {
    addInvoices = (newInvoice) => {
        return addDoc(invoiceCollectionRef, newInvoice);
    };

    updateInvoice = (id, updatedInvoice) => {
        const invoiceDoc = doc(db, "invoices", id);
        return updateDoc(invoiceDoc, updatedInvoice);
    };

    deleteInvoice = (id) => {
        const invoiceDoc = doc(db, "invoices", id);
        return deleteDoc(invoiceDoc);
    };

    getAllInvoices = () => {
        return getDocs(invoiceCollectionRef);
    };

    getInvoice = (id) => {
        const invoiceDoc = doc(db, "invoices", id);
        return getDoc(invoiceDoc);
    };
}

export default new InvoiceDataService();
