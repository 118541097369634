import { db } from "src/helpers/firebase";

import {
    collection,
    getDocs,
    getDoc,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

const tasksCollectionRef = collection(db, "Task");
class TaskDataService {
    addTasks = (newTask) => {
        return addDoc(tasksCollectionRef, newTask);
    };

    updateTask = (id, updatedTask) => {
        const taskDoc = doc(db, "Task", id);
        return updateDoc(taskDoc, updatedTask);
    };

    deleteTask = (id) => {
        const taskDoc = doc(db, "Task", id);
        return deleteDoc(taskDoc);
    };

    getAllTasks = () => {
        return getDocs(tasksCollectionRef);
    };

    getTask = (id) => {
        const taskDoc = doc(db, "Task", id);
        return getDoc(taskDoc);
    };
}

export default new TaskDataService();
