import React, { useEffect, useState } from "react";
// import './Timer.css'
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
//Firebase
import { db, auth } from 'src/helpers/firebase';
import { addDoc, collection, updateDoc } from 'firebase/firestore';

// material
import { Stack, TextField, IconButton, InputAdornment, Typography, Snackbar, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// component
import Iconify from '../../../components/Iconify';
import { NotificationManager } from "src/components/common/react-notifications";
import { Button, Input } from "reactstrap";
import date from 'date-and-time';
import { useAuthState } from "react-firebase-hooks/auth";


const TimeModal = () => {

    const navigate = useNavigate();
    const [newDocId, setNewDocId] = useState('');
    const [hour, setHour] = useState("0");
    const [minute, setMinute] = useState("0");
    const [seconds, setSeconds] = useState("0");
    const [project, setProject] = useState("0");
    const [task, setTask] = useState("");
    const [invoiced, setInvoiced] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const postsCollectionRef = collection(db, 'Task');
    const docid = db.collection('Task').doc().id;
    const now = new Date();
    const todaysdatetime = date.format(now, 'DD MMM YYYY HH:mm:ss A ');

    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

    const [open, setOpen] = React.useState(false);

    const [projectList, setProjectList] = useState([])
    const [user] = useAuthState(auth);

    useEffect(() => {
        console.log(user.uid)
    }, [user]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    // User Total Projects
    const fetchTotalProjects = async () => {
        try {
            const query = await db
                .collection("Projects")
                .where("uid", "==", user?.uid)
                .get()
                .then((snapshot) => {
                    const tempData = [];
                    snapshot.forEach((doc) => {

                        const data = doc.data();
                        tempData.push(data);
                    });
                    setProjectList(tempData);
                });
            // const data = await query.docs[0].data();
            // setProjectList(data);
        } catch (err) {
            console.error(err);
            alert("An error occured while fetching user data");
        }
    };

    useEffect(() => {
        fetchTotalProjects();
    }, [user, projectList]);

    // Get User Hourly Rate
    const [hourlyRate, setHourlyRate] = useState("")

    const fetchHourlyRate = async () => {
        try {
            const query = await db
                .collection("user")
                .where("uid", "==", user?.uid)
                .get();
            const data = await query.docs[0].data();
            setHourlyRate(data.hourlyRate);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        fetchHourlyRate();
    }, [user, hourlyRate]);

    let hourNumber = parseInt(hour);
    let minuteNumber = parseInt(minute);
    let secondNumber = parseInt(seconds);


    const doDocAction = (docRef, action) => (
        action(docRef, {
            hour: hourNumber,
            minute: minuteNumber,
            seconds: secondNumber,
            hourlyRate: hourlyRate,
            project,
            task,
            invoiced,
            date: todaysdatetime,
            dataid: docid,
            uid: user.uid,
        })
    );

    const formik = useFormik({
        initialValues: {
            hour: "",
            minute: "",
            seconds: "",
            project: "",
            task: "",
        },
        onSubmit: async () => {
            if (!newDocId) {
                doDocAction(postsCollectionRef, addDoc);
            } else {
                doDocAction(
                    db.collection('Task').doc(newDocId),
                    updateDoc
                );
            }
            // setOpen(true);
            await sleep(1000);
            window.location.reload(true);
        },
    });

    const { } = formik;


    return (
        <>
            <FormikProvider value={formik}>
                <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Success!
                    </Alert>
                </Snackbar>
                <Typography variant="h4" sx={{ mb: 5 }}>
                    New Task
                </Typography>
                <Form autoComplete="off" >

                    <Stack spacing={1}>

                        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>

                            <TextField
                                placeholder="Hour"
                                type="number"
                                value={hour}
                                fullWidth
                                onChange={(event) => setHour(event.target.value)}
                                style={{
                                    border: 'none',
                                    backgroundColor: 'white',
                                    height: '60px',
                                    fontSize: '30px',
                                }}
                            />
                            <TextField
                                placeholder="Minute"
                                type="number"
                                value={minute}
                                fullWidth
                                onChange={(event) => setMinute(event.target.value)}
                                style={{
                                    border: 'none',
                                    backgroundColor: 'white',
                                    height: '60px',
                                    fontSize: '30px',
                                }}
                            />
                            <TextField
                                placeholder="Seconds"
                                type="number"
                                value={seconds}
                                fullWidth
                                onChange={(event) => setSeconds(event.target.value)}
                                style={{
                                    border: 'none',
                                    backgroundColor: 'white',
                                    height: '60px',
                                    fontSize: '30px',
                                }}
                            />
                        </Stack>

                        <FormControl fullWidth>
                            <InputLabel >Project</InputLabel>
                            <Select
                                id="demo-simple-select"
                                label="Age"
                                onChange={(event) => {
                                    event.preventDefault()
                                    setProject(event.target.value)
                                }}
                            >
                                {projectList.map((user, e, index) => {
                                    return (
                                        <MenuItem
                                            className="church-select-item"
                                            key={user.name}
                                            id="category"
                                            value={user.name}
                                            defaultValue=""
                                        >
                                            {user.name}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <TextField
                            placeholder="Task"
                            type="text"
                            value={task}
                            fullWidth
                            onChange={(event) => setTask(event.target.value)}
                            style={{
                                border: 'none',
                                backgroundColor: 'white',
                                height: '60px',
                                fontSize: '30px',
                            }}
                        />





                        <LoadingButton fullWidth size="large" type="submit" variant="contained" >
                            Save
                        </LoadingButton>
                    </Stack>
                </Form>
            </FormikProvider>
        </>
    );
};

export default TimeModal;